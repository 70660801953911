.MDTable {
  width: 3500px;
  font-size: 0.8em;
  font-family: Verdana, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue,
    sans-serif;
  background-color: #fcfcfc;
}

.MDHeaderSticky {
  position: 'sticky';
  top: '0';
}

.MDTable th,
.MDTable td {
  padding: 0px 0px 0px 0px;
  border: none;
  padding-top: 1px;
  /*  background-color: #ebecfa; */
}
.MDTable tr {
  background-color: #dddcdc;
}

.MDTable thead tr th {
  padding: 0px 0px 1px 0px;
  text-shadow: 1px 1px 1px #80d2eb;
  font-weight: normal;
  font-size: 1em;
  background-color: #002f87;
  color: #ffffff;
  height: 22px;
}

.MDTable tbody tr td {
  border-top: solid 1px #c8cacd;
  font-size: 1em;
}

.MDcontainer {
  width: 100%;
  height: 600px;
  overflow: auto;
}

.zui-table {
  border: solid 1px #ddeeee;
  border-collapse: collapse;
  border-spacing: 0;
  font: normal 11px Arial, sans-serif;
}

.zui-table thead tr th {
  background-color: #ddefef;
  border: solid 1px #ddeeee;
  color: #336b6b;
  padding: 5px;
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
}

.zui-table tbody td {
  border: dotted 1px #ddeeee;
  color: #333;
  padding: 0px;
  text-shadow: 1px 1px 1px #fff;
  border-bottom: none;
  border-top: none;
}

.zui-table-rounded {
  border: none;
}

.zui-table-rounded thead th {
  background-color: #cfad70;
  border: none;
  text-shadow: 1px 1px 1px #ccc;
  color: #333;
}

.zui-table-rounded thead th:first-child {
  border-radius: 10px 0 0 0;
}

.zui-table-rounded thead th:last-child {
  border-radius: 0 10px 0 0;
}

.zui-table-rounded tbody tr td {
  border: none;
  border-top: dotted 1px #09840f;
  background-color: #eed592;
  border-bottom: none;
}

.zui-table-rounded tbody tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}

.zui-table-rounded tbody tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}

.zui-table-min {
  height: 0px;
}

.Color1 {
  border-color: #002f87;
}

.btn-customColor {
  background-color: #002f87;
  color: white;
}

.center {
  /*  display: flex; */
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  border: 5px #ccc;
  /* height: 100vh; */
}

.circle {
  width: 15px;
  height: 15px;
  background-color: #fa0000;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 40px;

  cursor: pointer;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px #c4fd01;
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
